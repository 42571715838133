import 'bootstrap/dist/css/bootstrap.css';
import '@popperjs/core';
import '../css/site.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.css';
import './navigation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "./contact";

AOS.init();

let contact = new Contact();
contact.init();

console.log('-- site.js has been loaded!');