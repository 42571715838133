﻿const navbar = document.querySelector('.navbar')
const hamburger = document.querySelector('.hamburger')
const navLinks = document.querySelector('.nav-links')
const navLinksLi = document.querySelectorAll('.nav-links li')

let scrollClassFlag = false;
window.addEventListener('scroll', () => {
    if (window.scrollY >= 100 && !scrollClassFlag) {
        navbar.classList.add('scrolled');//, 'bg-gradient'
        scrollClassFlag = true;
    } else if (window.scrollY < 100 && scrollClassFlag) {
        navbar.classList.remove('scrolled');//, 'bg-gradient'
        scrollClassFlag = false;
    }
})

hamburger.addEventListener('click', () => {
    navLinks.classList.toggle('active')
    hamburger.classList.toggle('active')
})

navLinksLi.forEach(li => li.addEventListener('click', () => {
    navLinksLi.forEach(li => li.classList.remove('active'))
    li.classList.add('active')

    hamburger.classList.remove('active')
    navLinks.classList.remove('active')

}))