﻿import axios from "axios";
import * as bootstrap from 'bootstrap';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

export default class Contact {

    init() {
        this.submitContactMessage();
        console.log('Init contact class - this should be constructor? ');
    }

    submitContactMessage = () => {

        $(document).on('click', "[name='submit-contact-message']", (e) => {
            e.preventDefault();

            if ($("[name='contact-form']").valid()) {

                let data = $("[name='contact-form']")[0]; //?? no id, why is this working? clean it.

                axios.post('/Home/SubmitContactForm', data)
                    .then((response) => {
                        console.log(response);
                        // clear form
                        // success message
                        $("#contact-form").find("input, textarea").each((element) => {
                            $(element).val("");
                        });

                        // todo: showToast(toastId) and abstract it to site.js?
                        var toastElList = [].slice.call(document.querySelectorAll('.toast'));
                        var tos = new bootstrap.Toast(toastElList[0]);
                        tos.show();

                    })
                    .catch(function (error) {
                        console.log(error); // do notification.error() like tcf. bootstrap toast.
                    });

            }
        });
    }
}